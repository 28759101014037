
import { Options, Vue } from 'vue-class-component';
import Menu from '@/components/Menu.vue';
import router from "@/router";

(function(w,d,u){
  var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
  var h=d.getElementsByTagName('script')[0];h.parentNode!.insertBefore(s,h);
})(window,document,'https://b24.vti-service.ru/upload/crm/site_button/loader_12_t14hjn.js');

@Options({
  components: {
    Menu,
  },
})
export default class App extends Vue {
  mounted() {
    // router.push('/')
  }
}
