
import config from '@/config';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: { 
  },
})

export default class NavigationBox extends Vue {
  @Prop() modelValue!: boolean;

  scroll(id: string) {
    window.location.hash = id;
    let element = document.getElementById(id) as HTMLDivElement;
    let topPos = element.offsetTop - 170;
    window.scrollTo(0, topPos);
  }

  openLK() {
    window.open(config.lkHost.host + 'api/auth/login', '_blank')
  }
}
