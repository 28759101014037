
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import config from '@/config';

@Options({
  components: { Button },
})
export default class Testing extends Vue {
  openSwag() {
    window.open(config.swagHost.host, '_blank')
  }
}
