import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitlePage = _resolveComponent("TitlePage")!
  const _component_OurBenefitsPage = _resolveComponent("OurBenefitsPage")!
  const _component_VdKassaAllows = _resolveComponent("VdKassaAllows")!
  const _component_PaymentProcess = _resolveComponent("PaymentProcess")!
  const _component_CommissionPercent = _resolveComponent("CommissionPercent")!
  const _component_Niches = _resolveComponent("Niches")!
  const _component_SimpleJoin = _resolveComponent("SimpleJoin")!
  const _component_Integration = _resolveComponent("Integration")!
  const _component_OurSpecialists = _resolveComponent("OurSpecialists")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_Testing = _resolveComponent("Testing")!
  const _component_Payments = _resolveComponent("Payments")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitlePage),
    _createVNode(_component_OurBenefitsPage),
    _createVNode(_component_VdKassaAllows),
    _createVNode(_component_PaymentProcess),
    _createVNode(_component_CommissionPercent),
    _createVNode(_component_Niches),
    _createVNode(_component_SimpleJoin),
    _createVNode(_component_Integration),
    _createVNode(_component_OurSpecialists),
    _createVNode(_component_Carousel),
    _createVNode(_component_Testing),
    _createVNode(_component_Payments)
  ], 64))
}