
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {},
})
export default class Button extends Vue {
  @Prop({ default: 'info' }) type!: string;
  @Prop() size!: string;
}
