const config = {
  lkHost: {
    host: process.env.VUE_APP_LK_HOST || "http://localhost:4300",
  },
  swagHost: {
    host: process.env.VUE_APP_SWAG_HOST || "http://localhost:4200",
  }
};

export default config;
