
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';
import config from '@/config';
import Footer from '@/components/Footer.vue';

@Options({
  components: { Button, Footer },
})
export default class Payments extends Vue {
  openLK() {
    window.open(config.lkHost.host + 'api/auth/login', '_blank')
  }
}
