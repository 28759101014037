
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';

@Options({
  components: { Button },
})
export default class TitlePage extends Vue {

  screenScroll = window.pageYOffset

  mounted() {
    this.updateScroll()
  }

  created() {
    window.addEventListener('scroll', this.updateScroll);
  }

  updateScroll() {
    this.screenScroll = window.pageYOffset
  }

  scroll(id: string) {
    window.location.hash = id;
    var element = document.getElementById(id) as HTMLDivElement;
    var topPos = element.offsetTop - 170;
    window.scrollTo(0, topPos);
  }
}
