
import { Options, Vue } from 'vue-class-component';
import TitlePage from '@/views/TitlePage.vue'
import OurBenefitsPage from '@/views/OurBenefitsPage.vue'
import VdKassaAllows from '@/views/VdKassaAllows.vue'
import PaymentProcess from '@/views/PaymentProcess.vue'
import CommissionPercent from '@/views/CommissionPercent.vue'
import Niches from '@/views/Niches.vue'
import SimpleJoin from '@/views/SimpleJoin.vue'
import Integration from '@/views/Integration.vue'
import OurSpecialists from '@/views/OurSpecialists.vue'
import Carousel from '@/views/Carousel/index.vue'
import Testing from '@/views/Testing.vue'
import Payments from '@/views/Payments.vue'

@Options({
  components: {
    TitlePage,
    OurBenefitsPage,
    VdKassaAllows,
    PaymentProcess,
    CommissionPercent,
    Niches,
    SimpleJoin,
    Integration,
    OurSpecialists,
    Payments,
    Testing,
    Carousel,
  },
})
export default class HomeView extends Vue {}
