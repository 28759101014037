
import { Options, Vue } from 'vue-class-component';
import Button from '@/components/Button.vue';

@Options({
  components: {
    Button
  },
})
export default class SimpleJoin extends Vue {}
