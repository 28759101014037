
import { Options, Vue } from 'vue-class-component';
import NavigationBox from '@/components/NavigationBox.vue'
import config from "@/config";

@Options({
  components: {NavigationBox},
})
export default class Menu extends Vue {
  showNavigationBox = false

  openLK() {
    window.open(config.lkHost.host + 'api/auth/login', '_blank')
  }

}
